import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "../RichTextRenderer"

// Background Image component
import Background from "../backgroundImage"

const Banner = ({
  data: {
    bannerTitle,
    bannerImage,
    title,
    subtitle,
    description,
    contactPersonImg,
    contactPersonName,
    contactPersonPosition,
  },
  customNav,
}) => {
  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 py-12 pb-8 mx-auto md:px-8 xl:px-0 md:pb-16">
        <div className="flex flex-col-reverse justify-between w-full md:flex-row">
          <div className="w-full">
            <h1 className="text-3xl font-bold tracking-tighter text-white uppercase md:text-5xl siteFont">
              {bannerTitle}
            </h1>
            <h1 className="mt-12 text-2xl font-bold tracking-tight text-white uppercase md:text-4xl siteFont">
              {title}
            </h1>
            <h1 className="mt-3 text-lg font-bold text-white md:text-xl">
              {subtitle}
            </h1>
            <div className="mt-6 text-base text-white md:text-lg Gray">
              {RichTextRenderer(description.json)}
            </div>
          </div>
          <div className="w-3/5 md:w-10/12">
            <Img
              fluid={bannerImage.fluid}
              alt={bannerImage.title}
              fade={false}
              loading="eager"
            />
          </div>
        </div>
        <div className="flex pt-6 md:pt-0">
          <div className="w-16">
            <Img fluid={contactPersonImg.fluid} alt={contactPersonImg.title} />
          </div>
          <div className="inline-block my-auto ml-4">
            <p className="mb-1 text-lg font-bold text-white">
              {contactPersonName}
            </p>
            <p className="text-white">{contactPersonPosition}</p>
          </div>
        </div>
      </div>
    </Background>
  )
}

export default Banner
