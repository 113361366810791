import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/ContactUs/banner"
import Details from "../components/ContactUs/details"

// Context API data
import { AppContext } from "../store/AppContext"

const ContactPage = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerImage,
    title,
    subtitle,
    description,
    contactPersonImg,
    contactPersonName,
    contactPersonPosition,
    siteMap,
  } = data.contact

  const bannerData = {
    bannerTitle,
    bannerImage,
    title,
    subtitle,
    description,
    contactPersonImg,
    contactPersonName,
    contactPersonPosition,
  }
  return (
    <Layout>
      <SEO title={ogTitle || "Contact Us"} description={ogDescription} />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Details data={siteMap} />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    contact: contentfulContactPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      title
      subtitle
      description {
        json
      }
      contactPersonImg {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      contactPersonName
      contactPersonPosition
      siteMap {
        description
        file {
          url
        }
      }
    }
  }
`

export default ContactPage
