import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

// Components
import ContactForm from "./contactForm"

const Details = ({ data }) => {
  return (
    <div className="flex flex-wrap justify-between p-4 mx-auto mb-8 border-blue-400 md:border-b md:pb-8 lg:px-0">
      <div className="max-w-4xl mx-auto my-8 rounded-lg md:my-12 contact">
        <ContactForm />
      </div>

      <div className="flex flex-col-reverse flex-wrap w-full mt-16 md:flex-row md:justify-between">
        <div className="w-full max-w-5xl px-4 mx-auto mb-4 md:w-9/12 lg:px-8">
          <img
            src={data.file.url}
            alt={data.description}
            className="h-56 md:h-full"
          />
        </div>
      </div>
    </div>
  )
}

export default Details
